export enum DeliveryTypes {
  // Bez dopravy
  NO_DELIVERY = 'c750abe6-e6c5-4796-bf8e-126319b2edf6',
  // Doprava ASAPRINT
  ASAPRINT = '22313bff-1229-4ddc-8451-acc2c6e29ba2',
  ASAPRINT_THURSDAY = '4f786b27-29f8-4bbe-be3c-a8db9a8e9e7a',
  ASAPRINT_THURSDAY_FREE = 'b37005a0-8850-4fa0-9225-ba05dd880154',
  // Osobny odber
  PICKUP = '97fbf614-072d-4b49-8015-ef91488495af',
  // Emailom
  EMAIL = 'b1d46620-79f4-42e7-98ba-52e3725fdfcf',
  // Kurier SPS
  COURIER_SPS = '9204c380-5dcc-4cc0-ac00-30bb8192c111',
  // Kurier TNT
  COURIER_TNT = 'cb09342f-e8a8-4e87-9869-2d1a648f17c9',
  // Kurier TopTrans
  COURIER_TOPTRANS = 'f7e9f4f5-d6fd-4c55-b9f6-bf578cbe2461',
  // Kurier DPD
  COURIER_DPD_DS = '4cf47ddb-e3ec-4a92-a2e7-5498455a7791',
  // Kurier Geis Parcel SK
  COURIER_GEIS_PARCEL = '68d8bbe4-220d-4492-b933-a8b97aa8d488',
  // Kurier Slovenská pošta
  COURIER_SLOVAK_POST = '574a5535-9408-4616-b827-6607d95b2fac',
  // Montaz ASAPrint
  ASSEMBLY = '67ef4dec-5c0b-4b6e-8fc6-0d8d56c30812',
  // Zasielkovna
  ZASIELKOVNA = '34454399-9400-47b3-9aa4-ccd68e42fc4a',
  // Dobierka
  CASH_ON_DELIVERY = 'c01e0e6a-cf06-4629-a157-5ff4737dd2ac',
}
